import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'

interface LayoutProps {}

export const Layout = ({ children }: React.PropsWithChildren<LayoutProps>) => (
  <>
    <Header />
    <main className="block lg:w-screen-lg px-4 sm:px-8 lg:px-0 lg:mx-auto">{children}</main>
    <Footer />
  </>
)
