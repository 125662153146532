import React, { useRef, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Header as HeaderType, InternalLink } from '../typings'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { HiMenu, HiX } from 'react-icons/hi'

const isInternalLink = (link: any): link is InternalLink => link['product']

export const Header = () => {
  const [open, setOpen] = useState(false)
  const navRef = useRef<HTMLElement>(null)
  useOnClickOutside(navRef, () => setOpen(false))

  const data = useStaticQuery(graphql`
    query {
      strapiHeader {
        id
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 32, placeholder: NONE)
            }
          }
        }
        menu
      }
    }
  `)

  const header: HeaderType = data.strapiHeader
  const logo = getImage(header.logo.localFile)
  const links = header.menu.map((item) => ({
    id: item.id,
    text: item.text,
    url: isInternalLink(item) ? `/${item.product.slug}` : item.url,
  }))

  return (
    <header className="sticky top-0 z-100 shadow">
      <nav
        className={`md:hidden absolute top-0 left-0 right-0 bg-white shadow transition duration-300 transform ${
          open ? 'translate-y-16' : '-translate-y-full'
        }`}
        ref={navRef}
      >
        <ul className="flex flex-col border-t divide-y">
          <NavLink to="/">Startseite</NavLink>
          {links.map((link) => (
            <NavLink key={link.id} to={link.url}>
              {link.text}
            </NavLink>
          ))}
        </ul>
      </nav>
      <div className="h-16 lg:h-20 px-4 md:px-8 relative flex items-center bg-white">
        <Link className="md:mr-20 flex flex-shrink-0" to="/">
          {logo && <GatsbyImage image={logo} alt="Apoverlag" />}
        </Link>
        <nav className="h-full hidden md:block">
          <ul className="h-full flex">
            <NavLink to="/">Startseite</NavLink>
            {links.map((link) => (
              <NavLink key={link.id} to={link.url}>
                {link.text}
              </NavLink>
            ))}
          </ul>
        </nav>
        <div className="flex-grow" />
        <button
          className="md:hidden p-2 -mr-2 focus:outline-none"
          onClick={() => setOpen(!open)}
          aria-label={open ? 'Menü schließen' : 'Menü öffnen'}
        >
          {open ? <HiX size={24} /> : <HiMenu size={24} />}
        </button>
      </div>
    </header>
  )
}

interface NavLinkProps {
  to: string
  children: string
}

const NavLink = ({ to, children }: NavLinkProps) => {
  const className = 'h-full px-4 flex items-center border-l-4 md:border-l-0 md:border-b-4 border-transparent hover:bg-primary-50'

  return (
    <li className="h-12 md:h-full font-bold flex-shrink-0 select-none">
      {to.startsWith('/') ? (
        <Link to={to} className={className} activeClassName="border-primary" partiallyActive={to !== '/'}>
          {children}
        </Link>
      ) : (
        <a className={className} href={to} target="_blank">
          {children}
        </a>
      )}
    </li>
  )
}
