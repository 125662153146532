import React from 'react'
import { Banner as BannerType } from '../typings'
import { Buttons } from './Button'
import { Card } from './Card'

interface BannerProps {
  banner: BannerType
  bgColor?: string
}

export const Banner = ({ banner, bgColor }: BannerProps) => (
  <div
    style={{ backgroundColor: bgColor }}
    className="full-width px-4 sm:px-12 md:px-0 py-8 sm:py-12 md:py-16 lg:py-20 bg-primary-background"
  >
    <Card className="w-full md:w-screen-sm lg:w-screen-md mx-auto px-4 py-8 lg:py-12 flex flex-col items-center">
      <p className="text-xl text-center font-bold mb-2 md:mb-3">{banner.title}</p>
      <p className="max-w-md text-center">{banner.description}</p>
      {banner.buttons?.length && (
        <div className="flex flex-col md:flex-row md:justify-center md:flex-wrap gap-2 md:gap-4 mt-4 md:mt-6">
          <Buttons buttons={banner.buttons} className="sm:w-60" />
        </div>
      )}
    </Card>
  </div>
)
