import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Footer as FooterType } from '../typings'

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiFooter {
        id
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 24, placeholder: NONE)
            }
          }
        }
        menu {
          id
          text
          url
        }
      }
    }
  `)

  const footer: FooterType = data.strapiFooter
  const logo = getImage(footer.logo.localFile)

  return (
    <footer className="sm:h-14 md:h-16 p-4 sm:px-8 sm:py-0 flex items-center justify-between relative bg-white shadow z-100">
      {logo && <GatsbyImage className="mr-4 md:mr-8 flex-shrink-0" image={logo} alt="Apoverlag" />}
      <ul className="flex flex-col sm:flex-row gap-2 sm:gap-8 lg:gap-12 text-xs sm:text-sm text-right tracking-wide">
        {footer.menu.map((link) => (
          <li key={link.id}>
            <a href={link.url} target="_blank">
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  )
}
