import React from 'react'

interface HeroProps {
  bgColor?: string
}

export const Hero = ({ children, bgColor }: React.PropsWithChildren<HeroProps>) => (
  <div
    style={{ backgroundColor: bgColor }}
    className="full-width h-144 sm:h-128 px-4 sm:px-8 flex items-center justify-center relative overflow-hidden bg-primary-background"
  >
    {children}
  </div>
)
