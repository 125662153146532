import React from 'react'
import { Link } from 'gatsby'
import { Button, ButtonType } from '../typings'

interface ButtonProps {
  button: Button
  className?: string
}

export const SingleButton = ({ button, className }: ButtonProps) => {
  const buttonClassName = [buttonClass[button.type], className].filter(Boolean).join(' ')

  return button.url.startsWith('/') ? (
    <Link className={buttonClassName} to={button.url.substr(1)}>
      {button.text}
    </Link>
  ) : (
    <a className={buttonClassName} href={button.url} target="_blank">
      {button.text}
    </a>
  )
}

interface ButtonsProps {
  buttons: Button[]
  className?: string
}

export const Buttons = ({ buttons, className }: ButtonsProps) => (
  <>
    {buttons.map((button) => (
      <SingleButton key={button.id} className={className} button={button} />
    ))}
  </>
)

const buttonClass: Record<ButtonType, string> = {
  primary: 'btn btn-solid',
  secondary: 'btn btn-outline',
  tertiary: 'btn-underline',
}
